import { Dialog } from '@headlessui/react';
import React from 'react';

const LoadingFullScreen = ({ text }: { text?: string }) => {
  return (
    <Dialog open={true} onClose={() => {}} className=" z-[100]">
      <div
        className="fixed inset-0 bg-shades-baseDark/40 backdrop-blur-sm"
        aria-hidden="true"
      />
      <Dialog.Panel>
        <div className="fixed flex flex-col justify-center items-center min-h-screen bg-black/70 z-[99999999999999999999] inset-0">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-colors-green -mt-32"></div>
          <p className="text-2xl mt-5 text-colors-green">
            {text || 'Loading...'}
          </p>
          {text === 'Generating Map' && (
            <p className="relative left-[2px] text-colors-green/60">
              (this may take up to a min)
            </p>
          )}
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default LoadingFullScreen;
