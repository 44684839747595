'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

export default function HomeButton() {
  const pathname = usePathname();
  const isLoginPage = pathname === '/login';

  if (!isLoginPage) {
    return null;
  }

  return (
    <Link href="/" className="cursor-pointer">
      <h1 className="text-[21px] ml-[17px] font-semibold text-white tracking-[0.15em]">
        <div className="flex justify-center">
          CAREER
          <span className="ml-[2px] text-colors-green">MAPPER</span>
        </div>
      </h1>
    </Link>
  );
}
