'use client';
import React, { useEffect, useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

import { Dialog, Popover, Portal, Transition } from '@headlessui/react';

import getMixPanelClient from '../../../lib/mixpanelBrowserClient';
import UserMenu from './UserMenu';
import clsx from 'clsx';
import { useLogger } from 'next-axiom';

const LoginButton = ({ user, homepageOnly }) => {
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const log = useLogger();

  const pathName = usePathname();

  if (homepageOnly && pathName !== '/') {
    return null;
  }

  if (!user) {
    return (
      <>
        {!pathName.startsWith('/login') && (
          <Link
            href={`/login?from=${pathName}`}
            className={clsx(
              homepageOnly
                ? 'mr-2 mt-1 text-shades-text/60 hover:brightness-125'
                : 'text-shades-baseLight/85 hover:text-shades-baseLight',
              'hidden md:block cursor-pointer font-semibold   tracking-wider z-[50] mr-2'
            )}
          >
            LOGIN
          </Link>
        )}
      </>
    );
  }

  return (
    <UserMenu
      user={user}
      showUserMenu={showUserMenu}
      setShowUserMenu={setShowUserMenu}
    >
      <div className="hidden md:block relative z-[200]">
        <Popover.Button
          className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-shades-baseLight hover:text-shades-baseDark outline-none hover:scale-110 transition-all duration-200 ease-in-out"
          onClick={() => {
            // console.info('POWINFO');
            // log.debug('User logged in', { userId: 42 });
            // log.info('User logged in INFO', { userId: 42 });
            // setTimeout(() => {
            //   throw new Error('test new ERROR');
            // }, 1000);
            // throw new Error('test new ERROR');
            setShowUserMenu(true);
          }}
          open={showUserMenu}
          aria-haspopup="true"
          aria-label="User Menu"
          aria-controls="userMenu"
        >
          <UserCircleIcon
            className={clsx(
              homepageOnly ? 'text-shades-text/60' : 'text-shades-surfaceLight',
              'h-8 w-8  transition-all flex-shrink-0 relative top-0.5 mr-2  pointer-events-none'
            )}
          />
        </Popover.Button>
      </div>
    </UserMenu>
  );
};

export default LoginButton;
